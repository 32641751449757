.headerMiddle {
    background-color: white;
    padding: 0px 0;
  
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .logo img {
        height: 100px;
        width: 320px;
      }
  
      .contactDetails {
        text-align: right;
  
        .subText {
          font-size: 14px;
          color: #777;
          margin-bottom: 5px;
        }
  
        .phoneNumber {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }
      }
    }
  
    @media (max-width: 768px) {
      .container {
        flex-direction: column;
        text-align: center;
  
        .contactDetails {
          margin-top: 15px;
        }
      }
    }
  }
  