.contactArea {
    background: url(../../images/library.jpg) center center/cover no-repeat local;
    position: relative;
    background-attachment: fixed;
    padding: 100px 0px;

    &::before {
        content: "";
        background-color: rgba(21, 26, 48, 0.6);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .contactInfo {

        @media (max-width:768px) {
            margin-bottom: 40px;
        }

        span {
            font-size: 16px;
            color: #ada282;
        }

        h2 {
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: white;
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;

                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }

        h6 {
            font-size: 18px;
            color: yellow;
            font-family: 'Open Sans', sans-serif;

            @media (max-width:1200px) {
                font-size: 16px;
            }
        }

        p {
            margin-top: 30px;
            color: #ddd;
            line-height: 30px;
        }
    }
}