@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

.blogPage {
  background: linear-gradient(135deg, #f5f7fa 0%, #f0f2f5 100%);
  padding: 60px 0;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
  color: #333;
  position: relative;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    gap: 30px;
    align-items: flex-start;
    position: relative;
    padding: 0 15px;
  }

  .contentWrapper {
    display: flex;
    flex-grow: 1;
    gap: 30px;
    width: 100%;
  }

  .blogContent {
    flex: 3;
    background-color: #fff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.08), 
      0 5px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-10px);
    }

    .blogTitle {
      font-size: 36px;
      color: #1a2b48;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1.2;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 80px;
        height: 4px;
        background: linear-gradient(90deg, #4a90e2, #50c878);
        border-radius: 2px;
      }
    }

    .blogBreadcrumb {
      font-size: 15px;
      color: #6b7280;
      margin-bottom: 25px;
      opacity: 0.8;
    }

    .blogImage {
      width: 100%;
      height: 450px;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.1), 
        0 10px 20px rgba(0, 0, 0, 0.05);
      transition: transform 0.4s ease;
      
      &:hover {
        transform: scale(1.02);
      }
    }

    .blogDescription {
      font-size: 18px;
      color: #333;
      line-height: 2;
      letter-spacing: 0.3px;
      margin-top: 30px;

      p, h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
      }

      a {
        color: #4a90e2;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #50c878;
          text-decoration: underline;
        }
      }
    }
  }

  .queryForm {
    position: sticky;
    top: 50px;
    width: 350px;
    background: linear-gradient(135deg, #fff 0%, #f9fafb 100%);
    padding: 35px;
    border-radius: 15px;
    box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.08), 
      0 5px 15px rgba(0, 0, 0, 0.05);
    z-index: 10;
    transition: all 0.3s ease;
    height: fit-content;

    &:hover {
      transform: scale(1.02);
      box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.1), 
        0 10px 20px rgba(0, 0, 0, 0.05);
    }

    h2 {
      font-size: 26px;
      color: #1a2b48;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 4px;
        background: linear-gradient(90deg, #4a90e2, #50c878);
        border-radius: 2px;
      }
    }

    p {
      font-size: 16px;
      color: #6b7280;
      margin-bottom: 25px;
      text-align: center;
      line-height: 1.6;
    }

    form {
      display: flex;
      flex-direction: column;

      input, textarea {
        width: 100%;
        padding: 14px;
        margin-bottom: 20px;
        border: 1px solid #e0e4e8;
        border-radius: 8px;
        font-size: 15px;
        color: #333;
        transition: all 0.3s ease;
        background-color: #f9fafb;

        &:focus {
          outline: none;
          border-color: #4a90e2;
          box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
        }

        &::placeholder {
          color: #a0aec0;
        }
      }

      textarea {
        resize: vertical;
        min-height: 150px;
      }

      button {
        background: linear-gradient(90deg, #4a90e2, #50c878);
        color: #fff;
        padding: 15px;
        font-size: 16px;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 10px 20px rgba(74, 144, 226, 0.3);
        }

        &:active {
          transform: translateY(-1px);
          box-shadow: 0 5px 10px rgba(74, 144, 226, 0.2);
        }
      }
    }
  }

  .loading, .error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    color: #6b7280;
    text-align: center;
  }

  .error {
    color: #e53e3e;
  }

  @media (max-width: 1400px) {
    .queryForm {
      right: 30px;
    }
  }

  @media (max-width: 1200px) {
    .queryForm {
      position: static;
      width: 100%;
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 0;

    .container {
      flex-direction: column;
      gap: 20px;
    }

    .contentWrapper {
      flex-direction: column;
      gap: 20px;
    }

    .blogContent {
      padding: 20px;
      
      .blogTitle {
        font-size: 28px;
      }

      .blogImage {
        height: 250px;
      }

      .blogDescription {
        font-size: 16px;
      }
    }

    .queryForm {
      position: relative;
      display: block;
      width: 100%;
      top: auto;
      right: auto;
      transform: none;
      margin-top: 20px;
    }
  }
}