.blogWrap {
    background: white;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);

        .blogImage img {
            transform: scale(1.05);
        }

        h3 a {
            color: #1d4ed8; // Slightly darker blue on hover
        }
    }

    .blogImage {
        position: relative;
        padding-top: 56.25%; // 16:9 aspect ratio
        width: 100%;
        overflow: hidden;
        background: #f3f4f6;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60px;
            background: linear-gradient(to top, rgba(255,255,255,0.2), transparent);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s ease;
        }
    }

    .blogContent {
        padding: 1.75rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: linear-gradient(to bottom, rgba(255,255,255,0.95), white);

        .firmName {
            color: #2563eb;
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 0.75rem;
            letter-spacing: 0.025em;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                display: inline-block;
                width: 3px;
                height: 3px;
                background-color: currentColor;
                border-radius: 50%;
                margin-right: 0.5rem;
            }
        }

        h3 {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.5;
            margin-bottom: 1rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            letter-spacing: -0.01em;
            
            a {
                color: #111827; // Darker for better contrast
                text-decoration: none;
                transition: color 0.2s ease;

                &:hover {
                    color: #1d4ed8;
                }
            }
        }

        .blogDescription {
            color: #4b5563;
            font-size: 0.9375rem;
            line-height: 1.6;
            margin-bottom: 1.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            opacity: 0.9;
        }

        .blogMeta {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.875rem;
            color: #6b7280;
            padding-top: 1.25rem;
            border-top: 1px solid #f3f4f6;

            span {
                &:first-child {
                    font-weight: 500;
                }
                
                &:last-child {
                    opacity: 0.8;
                }
            }
        }
    }
}