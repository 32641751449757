.headerTop {
    background: #f9f9f9;
    border-bottom: 1px solid #ececec;
  
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
  
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
  
        li {
          font-size: 14px;
          font-weight: 600;
          color: #333;
  
          i {
            margin-right: 5px;
            color: #f4a261;
          }
        }
      }
  
      .btnStyle {
        a {
          background: #f4a261;
          color: #fff;
          padding: 5px 10px;
          border-radius: 25px;
          font-size: 10px;
          text-transform: uppercase;
          transition: background 0.3s ease;
          font-weight: 900;
  
          &:hover {
            background: #e76f51;
          }
        }
      }
    }
  }
  