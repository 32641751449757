.portfolioImage {
    width: 100%;
    height: 350px;
    object-fit:cover;
   
}

.portfolioArea {
    padding: 80px 0;
    background-color: #f9f9f9;

    .gridPortfolio {
        .portfolio-card {
            margin-bottom: 30px;
            

            .portfolioItem {
                position: relative;
                overflow: hidden;
                border-radius: 10px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                transition: transform 0.3s ease, box-shadow 0.3s ease;
               

                &:hover {
                    transform: translateY(-10px);
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);

                    .portfolioContent {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                

                .portfolioContent {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 15px;
                    background: rgba(0, 0, 0, 0.7);
                    color: white;
                    text-align: center;
                    opacity: 0;
                    transform: translateY(100%);
                    transition: opacity 0.3s ease, transform 0.3s ease;

                    .portfolioSubtitle {
                        font-size: 14px;
                        color: white;
                        margin-bottom: 5px;
                    }

                    .portfolioTitle {
                        font-size: 18px;
                        margin-bottom: 10px;
                        color: white;
                        font-weight: 900;
                    }

                    .knowMoreButton {
                        background-color: #c0b596;
                        color: white;
                        border-radius: 20px;
                        padding: 5px 20px;
                        font-size: 14px;
                        transition: background-color 0.3s ease;

                        &:hover {
                            background-color: #a69470;
                        }
                    }
                }
            }
        }
    }
}
