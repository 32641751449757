.blogPostContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blogGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}

.blogCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
}

.blogCard:hover {
    transform: translateY(-5px);
}

.blogImage img {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.blogContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.blogContent h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.blogContent p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    max-height: 5.4em; /* Limit to 4 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.blogMeta {
    font-size: 12px;
    color: #888;
}

.readMore {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
    margin-top: auto;
}

.readMore:hover {
    text-decoration: underline;
}
