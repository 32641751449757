.contactForm{
    .error-message {
        color: red;
      }
    -webkit-box-shadow: 0px 0px 21px -4px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 21px -4px rgba(0,0,0,0.75);
box-shadow: 0px 0px 21px -4px rgba(0,0,0,0.75);

    .formInput{
        margin-bottom: 30px;
        input,
        textarea,
        select{
            background: white;
            height: 50px;
            padding: 6px 15px;
            color: black;
            border-radius: 5px;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid #e8e8e8;
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 14px;
            }
        }
        select{
            color: #bbb5a5;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: rgb(72, 70, 76) url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px;
            padding: 15px;
        }
        p{
            text-transform: uppercase;
            font-size: 2px;
            color: #c0b596;
        }
    }
    
    button{
        font-size: 15px;
        font-weight: 700;
        color: balck;
        padding: 18px 50px;
        border: 1px solid black;
        text-transform: capitalize;
        display: inline-block;
        transition: all .3s;
        background: white;
        border-radius: 1px;
        &:hover{
            background:black;
            color: white;
        }
    }
}