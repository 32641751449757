.headerBottom {
    background-color: #ffffff;
    border-top: 1px solid #ddd;
    padding: 10px 0;

    .container {
        display: flex;
        justify-content: center; /* Centers the menu on the screen */
        align-items: center;
    }

    .menuIcon {
        margin-right: 8px;
        font-size: 18px;
        display: inline-block;
        position: relative;
        top: -2px;
      }
    
    .headerBottomMainWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .mainMenuWrap {
        display: flex;
        width: 100%; /* Ensure it spans the entire width */
        justify-content: space-evenly; /* Distribute items evenly */
        align-items: center;

        @media (max-width: 768px) {
            display: none;
            width: 100%;
            flex-direction: column;
            background-color: #ffffff;
            border-top: 1px solid #ddd;
            padding: 10px 0;

            &.active {
                display: flex;
            }
        }

        ul.menu {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            justify-content: space-evenly; /* Distribute menu items evenly across the width */

            li {
                margin: 0;
                flex: 1; /* Ensures each item occupies equal space */

                @media (max-width: 768px) {
                    margin: 10px 0;
                }

                a {
                    text-decoration: none;
                    font-size: 16px;
                    color: #333;
                    font-weight: 600;
                    padding: 5px 0;
                    text-align: center;
                    display: block; /* Ensure block-level layout for consistent spacing */

                    &.active {
                        color: #c0b596;
                    }

                    &:hover {
                        color: #a4927c;
                    }
                }
            }
        }
    }
}
